// palette for 21 Grand Casino
$bg-color: #17140a;
$text-color: #ffffd2;
$primary: #f62c34;
$success: #4CAF50;
$info: #e4c669;
$danger: #F52C33;

//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

#rivalHeaderNavAndLogoSection img {
  height: 60px;
  margin: -7px 0 -7px 40px;
  max-width: 150px;
  width: auto !important;
  @media (max-width: 576px) {
      margin: -7px 0;
    }
}

//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}

